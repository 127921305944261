import { render, staticRenderFns } from "./CmsAccordion.vue?vue&type=template&id=991c3c12&scoped=true&"
import script from "./CmsAccordion.vue?vue&type=script&lang=ts&"
export * from "./CmsAccordion.vue?vue&type=script&lang=ts&"
import style0 from "./CmsAccordion.less?vue&type=style&index=0&id=991c3c12&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "991c3c12",
  null
  
)

export default component.exports