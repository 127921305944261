import { Vue, Component, Prop } from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';
import APP_CONST from '@/constants/AppConst';

@Component
export class BouncingPreloaderComponent extends Vue {
  @Prop() viewClass!: string;

  private screenText: ScreenText = new ScreenText();

  readonly STYLE = APP_CONST.STYLE;

  public getScreenText(key: string): string {
    return this.screenText.getScreenText(key);
  }
}