











import { BouncingPreloaderComponent } from '@/commoncomponents/bouncingpreloadercomponent/BouncingPreloaderComponent';
export default BouncingPreloaderComponent;
