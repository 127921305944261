import APP_UTILITIES from '@/utilities/commonFunctions';
import { Vue, Component} from 'vue-property-decorator';

@Component
export default class DeviceWidthCheckerMixin extends Vue {
    public isMobile: boolean = APP_UTILITIES.isMobile();
    public isMobilePortrait: boolean = APP_UTILITIES.isMobilePortrait();

    beforeMount(){
        this.checkClientDeviceWidth();
        window.addEventListener("resize", this.checkClientDeviceWidth);
    }
    
    checkClientDeviceWidth(){
        this.isMobile = APP_UTILITIES.isMobile();
        this.isMobilePortrait = APP_UTILITIES.isMobilePortrait();
    }

    destroyed(){
		window.removeEventListener("resize", this.checkClientDeviceWidth);
    }
}
