import { Inject, Prop, Watch } from "vue-property-decorator";

import CmsAccordion from "@/commoncomponents/CmsAccordion/CmsAccordion.vue";
import CmsCardCarousel from "@/commoncomponents/CmsCardCarousel/CmsCardCarousel.vue";
import CmsCardComponent from "@/commoncomponents/CmsCardComponent/CmsCardComponent.vue";
import CmsLabelComponent from "@/commoncomponents/CmsLabelComponent/CmsLabelComponent.vue";
import CmsTagComponent from "@/commoncomponents/CmsTagComponent/CmsTagComponent.vue";
import BreadcrumbComponent from "@/commoncomponents/breadcrumbComponent/BreadcrumbComponent.vue";
import MobileScreenUnavailable from "@/commoncomponents/mobileScreenUnavailable/MobileScreenUnavailable.vue";
import DeviceWidthCheckerMixin from "@/mixins/deviceWidthChecker.mixin";
import { Component, Mixins } from "vue-property-decorator";
import Checkmark from "../../../assets/images/cms/checkmark.svg";
import ChevronRight from "../../../assets/images/cms/chevron_right_nav_link.svg";
import Bullet from "../../../assets/images/cms/link_bullet_blue.svg";
import ResourceLink from "../../../assets/images/cms/resource_link.svg";
import ViewLink from "../../../assets/images/cms/view_primary_button.svg";
import DownloadLink from "../../../assets/images/cms/download_primary_button.svg";

import RichTextRenderer from "contentful-rich-text-vue-renderer";

import BouncingPreloaderComponent from '@/commoncomponents/bouncingpreloadercomponent/BouncingPreloaderComponent.vue';
import APP_CONST from "@/constants/AppConst";
import {
  MediaResourcePageData,
  getCopyrightMessage,
  mediaResourceResponseToData,
  isAuthorizedContent,
  checkCMSEnabledReRoute
} from "@/utilities/cmsUtilities";
import axios from "axios";
import CmsNotAuthorizedComponent from "@/commoncomponents/CmsNotAuthorizedComponent/CmsNotAuthorizedComponent.vue";
import { getContentfulGraphQLQuery, getRelatedContentfulGraphQLQuery } from "@/utilities/contentfulGraphQLHelpers";
import programListStore from "@/store/modules/programList";
import { AnalyticsInjectionKey, AnalyticsService, analyticsEventNames } from '@/analytics';

@Component({
  components: {
    "bread-crumb": BreadcrumbComponent,
    "mobile-unavailable-screen": MobileScreenUnavailable,
    "cms-card": CmsCardComponent,
    "collapsible-layout-card": CmsAccordion,
    "cms-tag-list": CmsTagComponent,
    "cms-content-label": CmsLabelComponent,
    "card-carousel": CmsCardCarousel,
    "bouncing-preloader": BouncingPreloaderComponent,
    'cms-not-authorized': CmsNotAuthorizedComponent,
  }
})
export default class RoutineLandingPage extends Mixins(
  DeviceWidthCheckerMixin
) {
  @Inject(AnalyticsInjectionKey)
  private readonly analyticsService!: AnalyticsService;
  
  //Images from assets folder
  checkmarkImage = Checkmark;
  resourceLinkImage = Bullet;
  navLinksChevron = ChevronRight;
  linkIcon = ResourceLink;

  buttonType: 'VIEW' | 'DOWNLOAD' | 'LINK' = 'DOWNLOAD';

  resourceId: string = "";
  @Prop()
  resourceIdQuery: string | undefined;

  routineData: MediaResourcePageData = {
    title: "",
    id: "",
    heroImage: "",
    objectivesList: [],
    tagList: [],
    labelData: [],
    navLinks: [],
    relatedContentCards: [],
    url: "",
    itemDescription: "",
    allowDownload: false,
    fileUrl: "",
  };
  loading: boolean = true;
  isAuthorized: boolean = true;


  /* istanbul ignore next */
  get lxEnabled() {
    return programListStore.appSetting;
  }
  @Watch('lxEnabled', { immediate: true, deep: true })
  cmsFeatureFlag(store: any) {
    if (checkCMSEnabledReRoute(store)) {
      this.$router.push({ path: '/' });
    }
  }


  setButtonType(data: MediaResourcePageData) {
    if (data.url && data.url.length) {
      this.buttonType = "LINK";
      this.linkIcon = ResourceLink;
    } else if (data.allowDownload) {
      this.buttonType = "DOWNLOAD";
      this.linkIcon = DownloadLink;
    } else {
      this.buttonType = "VIEW";
      this.linkIcon = ViewLink;
    }
  }

  async fetchData() {
    const url = `${APP_CONST.CONTENTFUL_GRAPHQL_URL}${APP_CONST.CONTENTFUL_GRAPHQL_SPACE}${APP_CONST.CONTENTFUL_GRAPHQL_ENV}`;
    const headers = { Authorization: `Bearer ${APP_CONST.CONTENTFUL_GRAPHQL_TOKEN}` };
    const method = 'post';
    return axios({
      url: url,
      headers: headers,
      method: method,
      data: {
        query: getContentfulGraphQLQuery("mediaResource", this.resourceId)
      }
    })
      .then(result => {
        let mediaResourceData = result;
        // Get related content
        axios({
          url: url,
          headers: headers,
          method: method,
          data: {
            query: getRelatedContentfulGraphQLQuery("mediaResource", this.resourceId, false)
          }
        }).then(relatedResult => {
          mediaResourceData.data.data.mediaResource = {
            ...mediaResourceData.data.data.mediaResource,
            ...relatedResult.data.data.mediaResource
          };
          const structuredData = mediaResourceResponseToData("mediaResource", result);
          this.isAuthorized = isAuthorizedContent(structuredData);

          this.routineData = {
            ...structuredData
          };
          this.setButtonType(structuredData);
          this.loading = false;

          return structuredData;
        }).catch(err => {
          console.error(`Failed to Fetch Related Content`, err);
        });
      }).catch(err => {
        console.error(`Failed to Fetch Landing Page`, err);
      });
  }

  async updatedId() {
    const id: string = this.$route.query.id as string || '';
    this.loading = true;
    this.resourceId = id || "";
    this.fetchData();
  }

  openCourse() {
    if (!!this.routineData.url && this.routineData.url.length) {
      //Has External URL
      window.open(this.routineData.url, "_blank");
    } else if (this.buttonType === 'VIEW') {
      //Send to ViewPdf page
      this.$router.push({ path: APP_CONST.APP_ROUTES.CMS_VIEW_PDF_LANDING.path, query: { id: this.routineData.id } });
    } else if (this.buttonType === "DOWNLOAD") {
      window.open(this.routineData.fileUrl, "_blank");
    }
    
    this.analyticsService.track(analyticsEventNames.LEARNING_MEDIA_RESOURCE_DOWNLOADED);
  }

  getButtonText() {
    switch (this.buttonType) {
      case "LINK":
        return 'RESOURCE';
        break;
      case "VIEW":
        return 'VIEW RESOURCE';
        break;
      case "DOWNLOAD":
        return 'DOWNLOAD RESOURCE';
        break;
      default:
        return 'RESOURCE';
        break;
    }

  }

  beforeMount() {
    const id = this.$route.query.id;
    if (id) {
      this.resourceId = id.toString();
    }
    this.$watch("$route", this.updatedId);
    // if(!this.courseData.courseId.length){
    this.fetchData();
    // }
  }

  getCopyrightMessage = getCopyrightMessage;

}
