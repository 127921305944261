import { Vue, Component, Prop } from "vue-property-decorator";
import APP_CONST from "@/constants/AppConst";

@Component({
  name: "cms-tag-list",
  props: {
    tagData: { type: Array }
  }
})
export default class CmsTagComponent extends Vue {
  @Prop({
    type: Array
  })
  tagData!: Array<String>;

  handleClick(tag: any) {
    this.$router.push({path: APP_CONST.APP_ROUTES.CMS_CATALOG.path, query: {tag: tag}});
  }
}
