












import CmsLabelComponent from "./CmsLabelComponent";
export default CmsLabelComponent;
